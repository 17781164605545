import React, { ReactNode } from 'react'
import * as styles from './index.module.css'

interface Props {
  width?: string | number
  height?: string | number
  children: string | ReactNode
  style?: React.CSSProperties
  disabled?: boolean
  type?: string | React.ButtonHTMLAttributes<HTMLButtonElement>
  onClick: () => void
}

const Button: React.FC<Props> = ({
  width = '9.75',
  height = '3.5rem',
  children = 'Button',
  style,
  disabled = false,
  type = 'button',
  onClick,
}) => (
  <button
    type={(type !== 'button') ? 'submit' : 'button'}
    className={styles.button}
    {...{ onClick, disabled }}
    style={{
      width,
      height,
      ...style,
    }}
  >
    {children}
  </button>
)

export default Button
