// extracted by mini-css-extract-plugin
export var root = "k_1";
export var heading = "k_w";
export var cnt = "k_4";
export var wrapper = "k_b";
export var offers = "k_5";
export var spacer = "k_6";
export var desc = "k_x";
export var figure = "k_7";
export var dots = "k_8";
export var info = "k_9";