import { CalculatorState, InputParams } from '.'
import { round } from '../../../utilities'

export default function calculate({
  rate,
  leasedAssetValue,
  initialPaymentProcent,
  termDuration,
}: InputParams): CalculatorState {
  const initialPaymentValue = round(leasedAssetValue * (initialPaymentProcent / 100))
  const credit = leasedAssetValue - initialPaymentValue
  const t = (1 + (rate / 12)) ** termDuration
  // eslint-disable-next-line no-mixed-operators
  const monthly = 1 - (-1 * (rate / 12) * t / (t - 1) * credit)
  const amount = monthly * termDuration // Сумма лизинговых платежей
  const saving = round(amount * 0.1666666508359339)
  const spending = round(amount - saving)
  // const value = amount + initialPaymentValue // Стоимость договора лизинга
  return {
    rate,
    leasedAssetValue,
    initialPaymentProcent,
    initialPaymentValue,
    termDuration,
    monthlyPayment: round(monthly),
    creditAmount: round(amount),
    saving,
    spending,
  }
}
