// extracted by mini-css-extract-plugin
export var wrapper = "c_b";
export var background = "c_k";
export var layer = "c_l";
export var column = "c_m";
export var moveDown = "c_n";
export var element = "c_p";
export var resultBg = "c_q";
export var moveRight = "c_r";
export var resultElement = "c_s";
export var calculation = "c_t";
export var content = "c_v";
export var heading = "c_w";
export var desc = "c_x";
export var action = "c_y";
export var calculator = "c_z";