import clsx from 'clsx'
import React from 'react'
import ExternalLinkIcon from '../../assets/svg/external-link.svg'
import Button from '../common/Button'
import Input from '../common/Input'
import * as styles from './index.module.css'

interface Wrapper {
  children?: React.ReactNode
  style?: React.CSSProperties
}

interface ContentBlockProps extends Wrapper {
  heading?: string | React.ReactNode
}

interface NavLinkProps extends Wrapper{
  el?: 'a' | 'span'
  href?: string
  external?: boolean
}

interface ContactButtonProps extends Wrapper {
  href: string
}

interface SubscribeProps {
  value: string
  placeholder: string
  onClick: () => void
  onChange: (value: string) => void
}

const Footer: React.FC<Wrapper> = ({
  children,
  style,
}) => (
  <footer style={style} className={styles.wrapper}>
    <div className={styles.footer}>
      {children}
    </div>
  </footer>
)

export const ContentBlock: React.FC<ContentBlockProps> = ({
  children,
  heading,
  style,
}) => (
  <div className={styles.contentBlock} style={style}>
    {heading && <div className={styles.contentBlockHeading}>{heading}</div>}
    {children}
  </div>
)

export const NavLink: React.FC<NavLinkProps> = ({
  el = 'a',
  children,
  href,
  external = false,
}) => {
  const target = (external) ? '_blank' : '_self'
  return (
    <div className={styles.linkWrapper}>
      { el === 'a' && (
        <a
          className={clsx(styles.link, { [styles.external]: external })}
          {...{ href, target }}
        >
          {children || href}
          {external && <ExternalLinkIcon />}
        </a>
      )}
      { el === 'span' && (
        <span
          className={clsx(styles.link, { [styles.external]: external })}
        >
          {children}
          {external && <ExternalLinkIcon />}
        </span>
      )}
    </div>
  )
}

export const ContactButton: React.FC<ContactButtonProps> = ({
  children,
  style,
  href,
}) => (
  <a
    href={href}
    style={style}
    className={styles.btn}
    target="_blank"
    rel="noreferrer"
  >
    {children}
  </a>
)

export const Contacts: React.FC<Wrapper> = ({
  children,
  style,
}) => (
  <div style={style} className={styles.btnGroup}>
    {children}
  </div>
)

export const Subscribe: React.FC<SubscribeProps> = ({
  value,
  placeholder,
  onClick,
  onChange,
}) => (
  <form className={styles.subscribe} method="POST" action="https://cp.unisender.com/ru/subscribe?hash=6wojxmuu198tuhisio8ma5opcpdepnusr9oznfbhjt5q74yjuw3jo" name="subscribtion_form">
    <Input
      {...{ value, placeholder, onChange }}
      name="email"
      style={{
        width: '100%',
        height: '3.5rem',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
      }}
    />
    <input type="hidden" name="charset" value="UTF-8" />
    <input type="hidden" name="default_list_id" value="1" />
    <input type="hidden" name="overwrite" value="2" />
    <input type="hidden" name="is_v5" value="1" />
    <Button
      {...{ onClick }}
      type="submit"
      style={{
        width: '100%',
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        boxShadow: 'none',
      }}
    >
      Подписаться
    </Button>
  </form>
)

export default Footer
