import React from 'react'
import * as styles from './index.module.css'
import OfferIcon1 from '../assets/offer-logo-1.svg'
import OfferIcon3 from '../assets/offer-logo-3.svg'
import OfferIcon5 from '../assets/offer-logo-5.svg'
import OfferIcon6 from '../assets/offer-logo-6.svg'

interface Props {
  heading: string | React.ReactNode
  desc: string | React.ReactNode
  children: string | React.ReactNode
  href: string
}

const Offer: React.FC<Props> = ({
  heading,
  desc,
  children,
  href,
}) => (
  <article className={styles.root}>
    <a href={href} target="_blank" rel="noreferrer">
      <div className={styles.grid}>
        <div className={styles.logo}>
          {children}
        </div>
        <h4 className={styles.heading}>
          {heading}
          <span className={styles.asterisk}>*</span>
        </h4>
        <div className={styles.desc}>
          {desc}
        </div>
      </div>
    </a>
  </article>
)

export default Offer
export {
  OfferIcon1,
  OfferIcon3,
  OfferIcon5,
  OfferIcon6,
}
