// extracted by mini-css-extract-plugin
export var wrapper = "l_b";
export var containerWrapper = "l_bb";
export var contentWrapper = "l_bc";
export var textWrapper = "l_bd";
export var text = "l_bf";
export var more = "l_bg";
export var spacer = "l_6";
export var imageWrapper = "l_bh";
export var imageMobile = "l_bj";
export var imageTablet = "l_bk";
export var imageDesktop = "l_bl";
export var dotGroup = "l_bm";
export var elementWrapper = "l_bn";
export var element = "l_p";