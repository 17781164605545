import { CalculatorState } from '.'

const initialState: CalculatorState = {
  rate: 0.17,
  leasedAssetValue: 5600000,
  initialPaymentProcent: 20,
  initialPaymentValue: 1120000,
  termDuration: 36,
  monthlyPayment: 159725,
  creditAmount: 5750108,
  saving: 958351,
  spending: 4791757,
}

export default initialState
