import axios from 'axios'
import React from 'react'
import Button from '../../common/Button'
import Checkbox from '../../common/Checkbox'
import Input from '../../common/Input'
import Textarea from '../../common/Textarea'
import * as styles from './index.module.css'
import { FormState } from './types'
import inititalState from './inititalState'

interface Props {
  state: FormState
  onChange: (state: FormState) => void
}

type Handler<T> = (name: keyof FormState) => (value: T) => void

const Form: React.FC<Props> = ({
  state,
  onChange,
}) => {
  const notEmpty = (value: string): boolean => !!value
  const minLen = (value: string, min: number): boolean => value.length >= min
  const handleChange: Handler<string | boolean> = (name) => (value) => {
    onChange({ ...state, [name]: value })
  }
  const validation = {
    name: notEmpty(state.name) && minLen(state.name, 3),
    email: notEmpty(state.email) && minLen(state.email, 5),
    message: notEmpty(state.message),
    agreement: state.agreement,
  }
  const validated = Object.values(validation).every((value) => value)
  const handleSubmit = () => {
    const data = JSON.stringify({
      module: 'leads',
      payload: {
        user_id: 29,
        name: state.name,
        email: state.email,
        reference_id: 8,
        comment: state.message,
        extra: {
          from: 'https://xn--80aedfcmgbyxfizsb3ck.xn--p1ai/',
        },
      },
    })
    axios({
      method: 'post',
      url: 'https://leasingcrm.ru/api/post/',
      headers: {
        'Content-Type': 'application/json',
        'X-Crypto-Token': '0abe075931becbb14f362c5f194322fecfa8e195bcbcd5c8aab273ec93daaba1',
        'X-Crypto-Secret': 'ee59b99ee33a02c75121ddef2f68f75d395cfd54bbeb92784dec38e0637ac32aff697a73f012cb6f8d78ae48e421e59dbc664a47a7bbcc12bcafebb50136da3e', 
      },
      data,
    }).then((response) => {
      console.log(JSON.stringify(response.data))
    }).catch((error) => {
      console.log(error)
    })
    axios.post('https://sm.trixta.ru/send-mail', {
      name: state.name,
      email: state.email,
      message: state.message,
      to: 'sl@solid-leasing.ru, ovc@digit-it.ru',
      subject: 'Новая заявка с сайта',
    }).then(() => {
      handleChange('success')('Заявка успешно отправлена, в ближайшее время она будет принята на рассмотрение.')
    }).catch((error) => {
      handleChange('error')(`При отправке произошла ошибка. Пожайлуйста, попробуйте позже, наши специалисты уже занимаются этой проблемой. Сообщение сервера: ${error.message}`)
    }).finally(() => setTimeout(() => onChange(inititalState), 5000))
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.inputsGroup}>
        <Input
          value={state.name}
          width="100%"
          placeholder="Ваше имя"
          validated={validation.name}
          onChange={handleChange('name')}
        />
        <Input
          value={state.email}
          width="100%"
          placeholder="Адрес эл.почты"
          validated={validation.email}
          onChange={handleChange('email')}
        />
        <Textarea
          value={state.message}
          width="100%"
          placeholder="Сообщение"
          validated={validation.message}
          onChange={handleChange('message')}
        />
        <Checkbox
          onChange={handleChange('agreement')}
          checked={state.agreement}
          validated={validation.agreement}
          name="agreement"
        >
          Согласен(-на) на обработку персональных данных
        </Checkbox>
      </div>
      { state.success && <div className={styles.success}>{state.success}</div> }
      { state.error && <div className={styles.error}>{state.error}</div> }
      { !state.success && !state.error && (
        <div className={styles.submit}>
          <Button
            disabled={!validated}
            onClick={handleSubmit}
            width="100%"
            height="2.625rem"
          >
            <span className={styles.submitCaption}>Отправить</span>
          </Button>
        </div>
      )}
    </div>
  )
}

export default Form
