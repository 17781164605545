import React from 'react'
import * as styles from './index.module.css'
import BubbleGroupIcon from '../../../assets/svg/bubble-group.svg'
import CircleGroupIcon from '../../../assets/svg/circle-group.svg'
import DotAngleGroupIcon from '../../../assets/svg/dot-angle-group.svg'

const BackgroundLayer: React.FC = () => (
  <div className={styles.wrapper}>
    <div className={styles.bg}>
      <DotAngleGroupIcon className={styles.dots} />
      <CircleGroupIcon className={styles.circle} />
      <BubbleGroupIcon className={styles.bubble} />
    </div>
  </div>
)

export default BackgroundLayer
