import clsx from 'clsx'
import React, { useState } from 'react'
import * as styles from './index.module.css'

interface Props {
  value: string,
  width?: string | number
  height?: string | number
  rows?: number
  placeholder?: string
  validated?: boolean
  onChange: (value: string) => void
}

const Textarea: React.FC<Props> = ({
  value,
  width = '11rem',
  height = 'auto',
  rows = 5,
  placeholder = '',
  validated = false,
  onChange,
}) => {
  const [touched, setTouched] = useState(false)
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e.target.value)
  }
  return (
    <textarea
      onChange={handleChange}
      onBlur={() => setTouched(true)}
      className={clsx(styles.textarea, !validated && touched && styles.error)}
      {...{ value, rows, placeholder }}
      style={{
        width,
        height,
      }}
    />
  )
}

export default Textarea
