import React from 'react'
import * as styles from './index.module.css'
import MinusIcon from '../../../assets/svg/minus.svg'
import PlusIcon from '../../../assets/svg/plus.svg'

interface Wrapper {
  children: string | React.ReactNode
}

interface AccordionTabProps extends Wrapper {
  id: string
  label: string | React.ReactNode
  multiple?: boolean
}

const AccordionTab: React.FC<AccordionTabProps> = ({
  id,
  children,
  label,
  multiple = false,
}) => (
  <div className={styles.root}>
    <div className={styles.tab}>
      <input
        type={multiple ? 'checkbox' : 'radio'}
        name="at"
        id={id}
      />
      <label className={styles.label} htmlFor={id}>
        <MinusIcon className={styles.openedIcon} />
        <PlusIcon className={styles.closedIcon} />
        {label}
      </label>
      <div className={styles.content}>
        <div className={styles.answer}>{children}</div>
      </div>
    </div>
  </div>
)

export default AccordionTab
