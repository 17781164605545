import clsx from 'clsx'
import React, { useState } from 'react'
import * as styles from './index.module.css'

interface Props {
  checked: boolean
  onChange: (value: boolean) => void
  name: string
  validated?: boolean
  children: React.ReactNode
}

const Checkbox: React.FC<Props> = ({
  checked,
  onChange,
  name,
  validated,
  children,
}) => {
  const [touched, setTouched] = useState(false)
  const handleChange = () => {
    setTouched(true)
    onChange(!checked)
  }
  return (
    <div className={clsx(styles.wrapper, !validated && touched && styles.error)}>
      <input
        className={styles.input}
        type="checkbox"
        id={name}
        {...{ name, checked, onChange: handleChange }}
      />
      <label
        className={styles.label}
        htmlFor={name}
      >
        <div className={clsx(styles.checkbox, checked && styles.checked)} />
        {children}
      </label>
    </div>
  )
}

export default Checkbox
