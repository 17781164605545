import React from 'react'
import clsx from 'clsx'
import * as styles from './index.module.css'

interface Props {
  checked: boolean
  onClick: () => void
}

const Burger: React.FC<Props> = ({
  checked,
  onClick,
}) => (
  <div
    aria-hidden="true"
    className={clsx(styles.wrapper, {
      [styles.active]: checked,
    })}
    {...{ onClick }}
  >
    <button
      type="button"
      className={styles.burger}

    >
      <span />
      <span />
      <span />
    </button>
  </div>
)

export default Burger
