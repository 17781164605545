// extracted by mini-css-extract-plugin
export var wrapper = "s_b";
export var settingsWrapper = "s_bv";
export var groupWrapper = "s_bw";
export var groupHeader = "s_bx";
export var value = "s_by";
export var valueIcon = "s_bz";
export var resultWrapper = "s_bB";
export var monthlyWrapper = "s_bC";
export var monthlyGroup = "s_bD";
export var monthlyControl = "s_bF";
export var monthlyValue = "s_bG";
export var result = "s_bH";
export var resultGroup = "s_bJ";
export var resultCaption = "s_bK";
export var resultValue = "s_bL";
export var submitWrapper = "s_bM";