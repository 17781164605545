import React from 'react'
import * as styles from './index.module.css'
import Logo from './assets/logo.svg'
import DotGroup from '../../assets/svg/dot-group-1.svg'

interface Props {
  heading: string | React.ReactNode
  desc: string | React.ReactNode
  children: string | React.ReactNode
}

const Offers: React.FC<Props> = ({
  heading,
  desc,
  children,
}) => (
  <section className={styles.root}>
    <div className={styles.heading}>
      {heading}
    </div>
    <div className={styles.cnt}>
      <div className={styles.spacer} />
      <div className={styles.offers}>
        <div className={styles.wrapper}>
          {children}
        </div>
        <div className={styles.info}>
          * Не является рекламой и носит исключительно информационный характер
        </div>
      </div>
      <div className={styles.spacer} />
      <div className={styles.spacer}>
        <div className={styles.figure} />
        <div className={styles.dots}>
          <DotGroup />
        </div>
      </div>
      <div className={styles.desc}>
        {desc}
        <Logo />
      </div>
    </div>
  </section>
)

export default Offers
