import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import clsx from 'clsx'
import * as styles from './index.module.css'
import DotGroupSecondary from '../../../assets/svg/dot-group-1.svg'
import DotGroupGray from '../../../assets/svg/dot-group-2.svg'

export default function BackgroundLayer() {
  return (
    <div className={styles.wrapper}>
      <div className={styles.guides}>
        <div className={styles.imageWrapper}>
          <StaticImage
            className={styles.mobile}
            alt="SomeText"
            src="../../../assets/images/main-bg-sm.jpg"
            layout="fixed"
            placeholder="blurred"
            quality={100}
          />
          <div className={styles.overlay}>
            <div className={styles.fill} />
            <StaticImage
              className={styles.tablet}
              alt="SomeText"
              src="../../../assets/images/main-bg-md-lg.jpg"
              layout="fixed"
              objectPosition="100% 100%"
              placeholder="blurred"
              quality={100}
            />
          </div>
          <div className={styles.overlay}>
            <div className={styles.fill} />
            <StaticImage
              className={styles.desktop}
              alt="SomeText"
              src="../../../assets/images/main-bg-xl.jpg"
              layout="fixed"
              objectPosition="100% 100%"
              placeholder="blurred"
              quality={100}
            />
          </div>
        </div>
        <div className={styles.spacer} />
        <div className={styles.liningWrapper}>
          <div className={styles.lining}>
            <DotGroupSecondary className={styles.dotsGroup} />
            <DotGroupGray className={clsx(styles.dotsGroup, styles.gray)} />
          </div>
        </div>
      </div>
    </div>
  )
}
