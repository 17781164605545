import React from 'react'
import DotGroupTriangle from '../../assets/svg/dot-group-triangle.svg'
import DotGroup from '../../assets/svg/dot-group-3.svg'
import * as styles from './index.module.css'

interface Props {
  heading: string | React.ReactNode
  desc: string | React.ReactNode
  action: React.ReactNode
  children: React.ReactNode
}

const Calculation: React.FC<Props> = ({
  heading,
  desc,
  action,
  children,
}) => (
  <section className={styles.wrapper}>
    <div className={styles.background}>
      <div className={styles.layer}>
        <div className={styles.column}>
          <DotGroup />
          <div className={styles.element} />
        </div>
        <div className={styles.resultBg}>
          <DotGroupTriangle />
          <div className={styles.resultElement} />
        </div>
      </div>
    </div>
    <div className={styles.calculation}>
      <div className={styles.content}>
        <div className={styles.heading}>
          {heading}
        </div>
        <div className={styles.desc}>
          {desc}
        </div>
        <div className={styles.action}>
          {action}
        </div>
      </div>
      <div className={styles.calculator}>
        {children}
      </div>
    </div>
  </section>
)

export default Calculation
