import React, { ReactNode } from 'react'
import * as styles from './index.module.css'

interface Props {
  el?: 'a' | 'button'
  href?: string
  children: ReactNode
  onClick: () => void
}

const Link: React.FC<Props> = ({
  el = 'a',
  href = '#',
  children,
  onClick,
}) => (
  <div className={styles.wrapper}>
    {el === 'a' && <a href={href} className={styles.link}>{children}</a>}
    {el === 'button' && <button {...{ onClick }} type="button" className={styles.link}>{children}</button>}
  </div>
)

export default Link
