import React, { Dispatch, SetStateAction } from 'react'
import * as styles from './calculator.module.css'
import RangeSlider from '../../common/RangeSlider'
import MinusCircleIcon from '../../../assets/svg/minus-circle.svg'
import PlusCircleIcon from '../../../assets/svg/plus-circle.svg'
import RubleIcon from '../../../assets/svg/ruble-currency.svg'
import { reduceMillions, thousandSeparator } from '../../../utilities'
import calculate from './calculate'
import Button from '../../common/Button'

export interface InputParams {
  rate: number
  leasedAssetValue: number
  initialPaymentProcent: number
  termDuration: number
}

export interface CalculatorState extends InputParams {
  initialPaymentValue: number
  monthlyPayment: number
  creditAmount: number
  saving: number
  spending: number
}

interface CalculatorProps {
  onSubmit: () => void
  state: CalculatorState
  set: Dispatch<SetStateAction<CalculatorState>>
}

export default function Calculator({ state, set, onSubmit }: CalculatorProps) {
  const {
    rate,
    leasedAssetValue,
    initialPaymentProcent,
    initialPaymentValue,
    termDuration,
    monthlyPayment,
    creditAmount,
    saving,
    spending,
  } = state

  let inputParams: InputParams = {
    rate,
    leasedAssetValue,
    initialPaymentProcent,
    termDuration,
  }

  const valueChangeHandler = (name: string) => (value: number) => {
    inputParams = {
      ...inputParams,
      [name]: value,
    }
    set(calculate(inputParams))
  }

  const termDurationIsMinimal = (termDuration - 6) < 12
  const termDurationIsMaximum = (termDuration + 6) > 60

  return (
    <div className={styles.wrapper}>
      <div className={styles.settingsWrapper}>
        <div className={styles.groupWrapper}>
          <div className={styles.groupHeader}>
            <span>Стоимость предмета лизинга</span>
            <div>
              <span className={styles.value}>{thousandSeparator(leasedAssetValue)}</span>
              <RubleIcon className={styles.valueIcon} />
            </div>
          </div>
          <RangeSlider
            value={leasedAssetValue}
            step={50000}
            min={1000000}
            max={50000000}
            unit="млн."
            representationFn={reduceMillions}
            onChange={valueChangeHandler('leasedAssetValue')}
          />
        </div>
        <div className={styles.groupWrapper}>
          <div className={styles.groupHeader}>
            <span>Первоначальный взнос</span>
            <span>{thousandSeparator(initialPaymentValue)}</span>
            <span className={styles.value}>{`${initialPaymentProcent} %`}</span>
          </div>
          <RangeSlider
            value={initialPaymentProcent}
            step={1}
            min={10}
            max={50}
            unit="%"
            onChange={valueChangeHandler('initialPaymentProcent')}
          />
        </div>
        <div className={styles.groupWrapper}>
          <div className={styles.groupHeader}>
            <span>Срок договора</span>
            <span className={styles.value}>{`${termDuration} мес.`}</span>
          </div>
          <RangeSlider
            value={termDuration}
            step={6}
            min={12}
            max={60}
            unit="мес."
            onChange={valueChangeHandler('termDuration')}
          />
        </div>
      </div>
      <div className={styles.resultWrapper}>
        <div className={styles.monthlyWrapper}>
          <div className={styles.resultCaption}>Ежемесячный платеж</div>
          <div className={styles.monthlyGroup}>
            <button
              type="button"
              disabled={termDurationIsMaximum}
              className={styles.monthlyControl}
              onClick={() => valueChangeHandler('termDuration')(termDuration + 6)}
            >
              <MinusCircleIcon />
            </button>
            <div className={styles.monthlyValue}>
              <span>{thousandSeparator(monthlyPayment)}</span>
              <RubleIcon />
            </div>
            <button
              type="button"
              disabled={termDurationIsMinimal}
              className={styles.monthlyControl}
              onClick={() => valueChangeHandler('termDuration')(termDuration - 6)}
            >
              <PlusCircleIcon />
            </button>
          </div>
        </div>
        <div className={styles.result}>
          <div className={styles.resultGroup}>
            <div className={styles.resultCaption}>Сумма договора</div>
            <div className={styles.resultValue}>
              <span>{thousandSeparator(creditAmount)}</span>
              <RubleIcon />
            </div>
          </div>
          <div className={styles.resultGroup}>
            <div className={styles.resultCaption}>Экономия</div>
            <div className={styles.resultValue}>
              <span>{thousandSeparator(saving)}</span>
              <RubleIcon />
            </div>
          </div>
          <div className={styles.resultGroup}>
            <div className={styles.resultCaption}>Затраты</div>
            <div className={styles.resultValue}>
              <span>{thousandSeparator(spending)}</span>
              <RubleIcon />
            </div>
          </div>
        </div>
        <div className={styles.submitWrapper}>
          <Button width="12.5rem" height="2.625rem" onClick={onSubmit}>Оставить заявку</Button>
        </div>
      </div>
    </div>
  )
}
