/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react'
import { Element, scroller, Link as ScrollLink } from 'react-scroll'
import { StaticImage } from 'gatsby-plugin-image'
import { v4 as uuid } from 'uuid'
import * as aboutStyles from '../components/about/about.module.css'
import Burger from '../components/common/Burger'
import Link from '../components/common/Link'
import Button from '../components/common/Button'
import Logo from '../components/common/Logo'
import Calculator from '../components/Calculation/Calculator'
import initialState from '../components/Calculation/Calculator/initialValues'
import DotGroup from '../assets/svg/dot-group-1.svg'
import ModalMenu from '../components/modal-menu'
import { FormState } from '../components/modal-menu/Form/types'
import formInititalState from '../components/modal-menu/Form/inititalState'
import Main, { CTA, Header } from '../components/Main'
import Partners, {
  Sng,
  Tatneft,
  Tattelecom,
  Sta,
} from '../components/Partners'
import Calculation from '../components/Calculation'
import Steps, { Step } from '../components/Steps'
import Footer, {
  ContactButton, Contacts, ContentBlock, NavLink, Subscribe,
} from '../components/Footer'
import AccordionTab from '../components/common/AccordionTab'
import Faq from '../components/Faq'
import PhoneIcon from '../assets/svg/phone.svg'
import EmailIcon from '../assets/svg/email.svg'
import InstagramIcon from '../assets/svg/instagram.svg'
import SEO from '../components/SEO'
import Offers from '../components/Offers'
import Offer, {
  OfferIcon1,
  OfferIcon3,
  OfferIcon5,
  OfferIcon6,
} from '../components/Offers/Offer'

function IndexPage() {
  const [menuIsActive, setMenuState] = useState(false)
  const [menuFixed, setMenuFixedState] = useState(false)
  const [calculatorState, calculatorDispatch] = useState(initialState)
  const [formState, setFormState] = useState<FormState>(formInititalState)
  const [subscribeState, setSubscribeState] = useState('')

  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset > 400) {
        setMenuFixedState(true)
      } else {
        setMenuFixedState(false)
      }
    }
  }, [])
  const menuToggle = () => setMenuState(!menuIsActive)
  const handleScroll = (to: string) => scroller.scrollTo(to, { smooth: true })
  const request = () => {
    const message = 'Предварительный расчет лизинга: '
      + `Стоимость предмета лизинга: ${calculatorState.leasedAssetValue} руб.\n`
      + `Первоначальный взнос: ${calculatorState.initialPaymentValue} руб.(${calculatorState.initialPaymentProcent}%)\n`
      + `Срок договора: ${calculatorState.termDuration} мес.\n`
      + `Ежемесячный платеж: ${calculatorState.monthlyPayment} руб.\n`
    setFormState({ ...formState, message })
    setMenuState(true)
  }
  return (
    <main>
      <SEO title="Новые рубежи" />
      <ModalMenu
        toggle={menuToggle}
        visible={menuIsActive}
        {...{ formState, setFormState }}
      />
      <Element name="main">
        <Main>
          <Header fixed={menuFixed || menuIsActive}>
            <Logo />
            <Burger
              checked={menuIsActive}
              onClick={menuToggle}
            />
          </Header>
          <CTA
            heading={<h1>Новые <span>рубежи</span></h1>}
            subheading={<h2>Лизинг с индивидуальным подходом</h2>}
          >
            <Button onClick={() => handleScroll('calculation')}>Расчет лизинга</Button>
            <Link el="button" onClick={menuToggle}>Оставить заявку</Link>
          </CTA>
        </Main>
      </Element>
      <Partners>
        <Tatneft alt="ПАО «Татнефть»" />
        <Sng alt="ПАО «Сургутнефтегаз»" />
        <Tattelecom alt="ПАО «Таттелеком»" />
        <Sta alt="ООО «Совтрансавто Логистика Рус»" />
      </Partners>
      <Element name="calculation">
        <Calculation
          heading={<h3>Предварительный расчет лизинга</h3>}
          desc={
            <span>Стоимость предмета лизинга и приведенные расчеты являются предварительными</span>
          }
          action={
            <Button width="12.5rem" height="2.625rem" onClick={request}>Оставить заявку</Button>
          }
        >
          <Calculator state={calculatorState} set={calculatorDispatch} onSubmit={request} />
        </Calculation>
        <Steps heading={<h3>Как проходит сделка по лизингу</h3>}>
          {[
            {
              id: uuid(), count: '01', heading: 'Выбор предмета лизина', desc: 'Вы выбираете предмет лизинга у любого официального дилера',
            },
            {
              id: uuid(), count: '02', heading: 'Оформление', desc: 'Подписываете договоры лизинга и купли-продажи с поставщиком',
            },
            {
              id: uuid(), count: '03', heading: 'Получение', desc: 'Получаете предмет лизинга',
            },
            {
              id: uuid(), count: '04', heading: 'Оплата', desc: 'Пользуетесь и вносите лизинговые платежи',
            },
          ].map((step) => (
            <Step
              key={step.id}
              count={step.count}
              heading={<h4>{step.heading}</h4>}
            >
              {step.desc}
            </Step>
          ))}
        </Steps>
      </Element>
      <Element name="about">
        <section className={aboutStyles.wrapper}>
          <div className={aboutStyles.containerWrapper}>
            <div className={aboutStyles.contentWrapper}>
              <h3>О лизинге</h3>
              <div className={aboutStyles.textWrapper}>
                <p className={aboutStyles.text}>
                  Лизинг это – вид финансовых услуг, форма кредитования для приобретения транспорта,
                  оборудования, недвижимости. По сути, лизинг ― это долгосрочная аренда имущества с
                  последующим правом выкупа, обладающая некоторыми налоговыми преференциями.
                </p>
                <p className={aboutStyles.text}>Предметом лизинга могут быть транспортные средства,
                  предприятия, здания, сооружения и оборудование. Не могут быть предметом лизинга
                  земельные участки и другие природные объекты, а также имущество, которое,
                  согласно Федеральному законодательству, не является предметом свободного
                  обращения или для которого установлен особый порядок обращения.
                </p>
                <p className={aboutStyles.text}>
                  Предмет лизинга, переданный во временное владение и пользование лизингополучателю,
                  является собственностью лизингодателя. Предмет лизинга, переданный
                  лизингополучателю по договору финансового лизинга, учитывается на
                  балансе лизингодателя или лизингополучателя по соглашению сторон.
                </p>
              </div>
            </div>
            <div className={aboutStyles.spacer} />
            <div className={aboutStyles.imageWrapper}>
              <div className={aboutStyles.dotGroup}>
                <DotGroup />
              </div>
              <StaticImage
                objectFit="none"
                className={aboutStyles.imageMobile}
                alt="about us"
                src="../assets/images/about.jpg"
                placeholder="blurred"
                quality={100}
              />
              <StaticImage
                className={aboutStyles.imageTablet}
                alt="about us"
                src="../assets/images/about.jpg"
                placeholder="blurred"
                quality={100}
              />
              <StaticImage
                objectFit="none"
                className={aboutStyles.imageDesktop}
                alt="about us"
                src="../assets/images/about.jpg"
                layout="fixed"
                objectPosition="100% 100%"
                placeholder="blurred"
                quality={100}
              />
            </div>
            <div className={aboutStyles.elementWrapper}>
              <div className={aboutStyles.element} />
            </div>
          </div>
        </section>
      </Element>
      <Element name="faq">
        <Faq
          heading={<h3>Вопросы и ответы</h3>}
          desc={<p>Мы собрали для Вас список часто задаваемых вопросов и ответов на них.</p>}
        >
          {[
            {
              id: uuid(),
              label: 'Как долго происходит оформление лизинговой сделки?',
              content: 'Срок оформления лизинговой сделки намного меньше срока оформления кредита. Срок предварительного одобрения лизинговой заявки — 1 день. Срок оформления – от 3 рабочих дней.',
            },
            {
              id: uuid(),
              label: 'Как оптимизировать налог на прибыль?',
              content: 'В отношении амортизируемых основных средств, являющихся предметом договора лизинга, применяется специальный коэффициент, но не выше 3, что позволяет налогоплательщику амортизировать имущество в 3 раза быстрее, тем самым экономя на налоге на прибыль.',
            },
            {
              id: uuid(),
              label: 'Какую экономию я получу?',
              content: 'Сумма договора лизинга, включая авансовый платеж, практически полностью (не считая НДС) относится на расходы. Это одно из основных преимуществ лизинга по сравнению с другими финансовыми инструментами, поскольку отнесение на расходы уменьшает налогооблагаемую базу, что позволяет клиенту (лизингополучателю) получить существенную экономию по налогу на прибыль.',
            },
            {
              id: uuid(),
              label: 'Я смогу упростить бухгалтерский учет?',
              content: 'Да, так как по основным средствам, начислению амортизации, выплате части налогов и управлению долгом учет осуществляет лизинговая компания.',
            },
            {
              id: uuid(),
              label: 'Я смогу получить субсидии?',
              content: 'Приобретая коммерческий транспорт в лизинг, Вы можете получить безвозмездную безвозвратную субсидию до 30% от стоимости предмета лизинга.',
            },
            {
              id: uuid(),
              label: 'Я могу выбрать схему погашения?',
              content: 'В договоре лизинга можно предусмотреть использование более удобных, гибких схем погашения задолженности.',
            },
            {
              id: uuid(),
              label: 'Я смогу заявить НДС к вычету?',
              content: 'Лизинговые платежи, включая авансовый платеж, содержат в себе 20% НДС.',
            },
          ].map((tab) => (
            <AccordionTab
              label={tab.label}
              id={tab.id}
              key={tab.id}
            >
              {tab.content}
            </AccordionTab>
          ))}

        </Faq>
      </Element>
      <Element name="offers">
        <Offers
          heading={<h3>Предложения партнёров</h3>}
          // eslint-disable-next-line max-len
          desc={<div><p>Мы заинтересованы в долгосрочных отношениях с компаниями, которые разделяют наши цели и философию: создание высококачественных продуктов и услуг для наших клиентов.</p><p>Вместе с ними мы строим успешное предпринимательское сообщество, основанное на взаимном доверии и сотрудничестве.</p></div>}
        >
          <Offer
            heading="АО «Солид-товарные рынки»"
            desc={<p>Брокерское обслуживание на бирже СПБМТСБ</p>}
            href="https://solid-tr.ru/"
          >
            <OfferIcon1 />
          </Offer>

          <Offer
            heading="InvoiceCafe"
            desc={<p>Инвестирование в проекты мсб на принципах краудлендинга</p>}
            href="https://invoice.cafe/"
          >
            <StaticImage
              objectFit="none"
              alt="offer logo"
              src="../components/Offers/assets/offer-logo-2.png"
              placeholder="blurred"
              quality={100}
            />
          </Offer>

          <Offer
            heading="Солид Банк"
            desc={<p>Услуги бизнесу: кредиты, депозиты, РКО и ВЭД</p>}
            href="https://solidbank.ru/"
          >
            <OfferIcon3 />
          </Offer>

          <Offer
            heading="Независимое агентство «Аналитика товарных рынков»"
            desc={<p>Аналитический терминал &quot;КОММОД&quot;</p>}
            href="https://na-atr.ru/"
          >
            <StaticImage
              objectFit="none"
              alt="offer logo"
              src="../components/Offers/assets/offer-logo-4.png"
              placeholder="blurred"
              quality={100}
            />
          </Offer>

          <Offer
            heading="ИФК Солид"
            // eslint-disable-next-line max-len
            desc={<p>Получите 13%+1% к своим инвестициям. Откройте инвестиционный счет и воспользуйтесь налоговым вычетом</p>}
            href="https://solidbroker.ru/"
          >
            <OfferIcon5 />
          </Offer>

          <Offer
            heading="УК Солид менеджмент"
            // eslint-disable-next-line max-len
            desc={<p>Открытый паевой инвестиционный фонд рыночных финансовых инструментов «Солид-Инвест». Доходность за последний год 41.48%</p>}
            href="https://solid-mn.ru/"
          >
            <OfferIcon6 />
          </Offer>
        </Offers>
      </Element>
      <Footer>
        <ContentBlock heading={<h3>Солид Лизинг</h3>}>
          {[
            {
              id: uuid(), to: 'main', external: false, text: 'Главная',
            },
            {
              id: uuid(), to: 'calculation', external: false, text: 'Калькулятор',
            },
            {
              id: uuid(), to: 'about', external: false, text: 'О лизинге',
            },
            {
              id: uuid(), to: 'faq', external: false, text: 'Вопросы и ответы',
            },
          ].map((link) => (
            <ScrollLink to={link.to} smooth key={link.id}>
              <NavLink el="span">{link.text}</NavLink>
            </ScrollLink>
          ))}
          <NavLink href="https://solidleasing.ru" external>Инвесторам</NavLink>
        </ContentBlock>
        <ContentBlock heading={<h3>Связаться</h3>}>
          <Contacts>
            <ContactButton href="tel:+74957757710"><PhoneIcon /></ContactButton>
            <ContactButton href="mailto:sl@solid-leasing.ru"><EmailIcon /></ContactButton>
            <ContactButton href="https://www.instagram.com/solid_leasing/"><InstagramIcon /></ContactButton>
          </Contacts>
          <p>
            Телефон: +7 (495) 775-77-10
            <br />
            Эл. почта: sl@solid-leasing.ru
          </p>
        </ContentBlock>
        <ContentBlock heading={<h3>Подписка</h3>}>
          <Subscribe
            placeholder="Новостная рассылка"
            value={subscribeState}
            onChange={(value) => setSubscribeState(value)}
            onClick={() => console.log()}
          />
        </ContentBlock>
        <ContentBlock>
          123007, Москва, Хорошевское шоссе, дом 32 А, пом. XXVIII
          <br />
          Филиал: 420107, Казань, ул. Петербургская, д. 78
        </ContentBlock>
        <ContentBlock style={{ fontSize: '0.875rem', fontWeight: 300, padding: '4rem 0' }}>
          Вся информация, касающаяся условий лизинговых программ, носит
          информационный характер и не является публичной офертой, в
          соответствии с положениями статьи 437(2) Гражданского кодекса
          Российской Федерации. Для получения подробной информации,
          пожалуйста, обращайтесь к клиентским менеджерам ООО «Солид-Лизинг».
        </ContentBlock>
        <ContentBlock>
          <span style={{ fontSize: '0.875rem' }}>© ООО «Солид-Лизинг», 2021</span>
        </ContentBlock>
        <ContentBlock>
          <a
            href="https://digit-it.ru"
            rel="noreferrer"
            target="_blank"
            style={{ fontSize: '0.875rem' }}
          >
            Разработано в DGT
          </a>
        </ContentBlock>
      </Footer>
    </main>
  )
}

export default IndexPage
