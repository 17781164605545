import clsx from 'clsx'
import React from 'react'
import BackgroundLayer from './BackgroundLayer'
import * as styles from './index.module.css'

interface Wrapper {
  children: React.ReactNode
}

interface HeaderProps extends Wrapper {
  fixed?: boolean
}

interface CTAProps extends Wrapper{
  heading: string | React.ReactNode
  subheading: string | React.ReactNode
}

const Main: React.FC<Wrapper> = ({
  children,
}) => (
  <section className={styles.wrapper}>
    <BackgroundLayer />
    {children}
  </section>
)

export const Header: React.FC<HeaderProps> = ({
  fixed = false,
  children,
}) => (
  <div className={clsx(
    styles.headerWrapper,
    { [styles.fixed]: fixed },
  )}
  >
    <header className={styles.header}>
      {children}
    </header>
  </div>
)

export const CTA: React.FC<CTAProps> = ({
  heading,
  subheading,
  children,
}) => (
  <div className={styles.box}>
    <div className={styles.cta}>
      {heading}
      {subheading}
      <div className={styles.actions}>
        {children}
      </div>
    </div>
  </div>
)

export default Main
