// extracted by mini-css-extract-plugin
export var root = "j_1";
export var wrapper = "j_b";
export var background = "j_k";
export var layer = "j_l";
export var column = "j_m";
export var moveRight = "j_r";
export var element = "j_p";
export var head = "j_2";
export var heading = "j_w";
export var desc = "j_x";
export var faq = "j_3";