import React, { Dispatch, ReactNode, SetStateAction } from 'react'
import * as styles from './range-slider.module.css'

type Unit = string | ReactNode | undefined
type Value = number
type ReprFn = (v: Value) => string

interface RangeSliderProps {
  value: Value
  step?: Value
  min?: Value
  max?: Value
  unit?: Unit
  representationFn?: ReprFn
  onChange: Dispatch<SetStateAction<number>> | ((value: number) => void)
}

export default function RangeSlider({
  value,
  step = 1,
  min = 0,
  max = 100,
  unit,
  representationFn = (v) => v.toString(),
  onChange,
}: RangeSliderProps) {
  const onChangeHandler = (e: React.FormEvent<HTMLInputElement>) => {
    const newValue = Number(e.currentTarget.value)
    onChange(newValue)
  }
  const valueRepresentation = (v: Value, u: Unit, rFn: ReprFn): string => `${rFn(v)} ${u}`
  return (
    <div className={styles.wrapper}>
      <input
        type="range"
        className={styles.slider}
        value={value}
        step={step}
        min={min}
        max={max}
        onChange={onChangeHandler}
      />
      <div className={styles.legend}>
        <span>{valueRepresentation(min, unit, representationFn)}</span>
        <span>{valueRepresentation(max, unit, representationFn)}</span>
      </div>
    </div>
  )
}
