import React from 'react'
import clsx from 'clsx'
import { v4 as uuid } from 'uuid'
import { Link as ScrollLink } from 'react-scroll'
import * as styles from './menu.module.css'
import PhoneIcon from '../../assets/svg/phone.svg'
import EmailIcon from '../../assets/svg/email.svg'
import InstagramIcon from '../../assets/svg/instagram.svg'
import Form from './Form'
import { FormState } from './Form/types'
import Footer, {
  ContactButton,
  Contacts,
  ContentBlock,
  NavLink,
} from '../Footer'

interface Props {
  toggle: () => void
  visible: boolean,
  formState: FormState,
  setFormState: React.Dispatch<React.SetStateAction<FormState>>
}

type Handler = (data: FormState) => void

const ModalMenu: React.FC<Props> = ({
  toggle,
  visible,
  formState,
  setFormState,
}) => {
  const handleChange: Handler = (data) => setFormState({ ...formState, ...data })

  return (
    <div className={clsx(styles.wrapper, visible && styles.show)}>
      <Footer>
        <ContentBlock heading={<h3>Солид Лизинг</h3>}>
          {[
            {
              id: uuid(), to: 'main', external: false, text: 'Главная',
            },
            {
              id: uuid(), to: 'calculation', external: false, text: 'Калькулятор',
            },
            {
              id: uuid(), to: 'about', external: false, text: 'О лизинге',
            },
            {
              id: uuid(), to: 'faq', external: false, text: 'Вопросы и ответы',
            },
          ].map((link) => (
            <ScrollLink to={link.to} smooth key={link.id} onClick={toggle}>
              <NavLink el="span">{link.text}</NavLink>
            </ScrollLink>
          ))}
          <NavLink href="https://solidleasing.ru" external>Инвесторам</NavLink>
        </ContentBlock>
        <ContentBlock heading={<h3>Связаться</h3>}>
          <Contacts>
            <ContactButton href="tel:+74957757710"><PhoneIcon /></ContactButton>
            <ContactButton href="mailto:sl@solid-leasing.ru"><EmailIcon /></ContactButton>
            <ContactButton href="https://www.instagram.com/solid_leasing/"><InstagramIcon /></ContactButton>
          </Contacts>
          <p>
            Телефон: +7 (495) 775-77-10
            <br />
            Эл. почта: sl@solid-leasing.ru
          </p>
        </ContentBlock>
        <ContentBlock heading={<h3>Напишите нам</h3>}>
          <Form
            state={formState}
            onChange={handleChange}
          />
        </ContentBlock>
        <ContentBlock />
        <ContentBlock style={{ fontSize: '0.875rem', fontWeight: 300, padding: '4rem 0' }}>
          Вся информация, касающаяся условий лизинговых программ, носит
          информационный характер и не является публичной офертой, в
          соответствии с положениями статьи 437(2) Гражданского кодекса
          Российской Федерации. Для получения подробной информации,
          пожалуйста, обращайтесь к клиентским менеджерам ООО «Солид-Лизинг».
        </ContentBlock>
        <ContentBlock>
          <span style={{ fontSize: '0.875rem' }}>© ООО «Солид-Лизинг», 2021</span>
        </ContentBlock>
        <ContentBlock>
          <a
            href="https://digit-it.ru"
            rel="noreferrer"
            target="_blank"
            style={{ fontSize: '0.875rem' }}
          >
            Разработано в DGT
          </a>
        </ContentBlock>
      </Footer>
    </div>
  )
}

export default ModalMenu
