import React from 'react'
import * as styles from './index.module.css'
import DotGroupTriangle from '../../assets/svg/dot-group-triangle.svg'

interface Props {
  heading: string | React.ReactNode
  desc: string | React.ReactNode
  children: string | React.ReactNode
}

const Faq: React.FC<Props> = ({
  heading,
  desc,
  children,
}) => (
  <section className={styles.root}>
    <div className={styles.background}>
      <div className={styles.layer}>
        <div className={styles.column}>
          <DotGroupTriangle />
          <div className={styles.element} />
        </div>
      </div>
    </div>
    <div className={styles.wrapper}>
      <div className={styles.head}>
        <div className={styles.heading}>
          {heading}
        </div>
        <div className={styles.desc}>
          {desc}
        </div>
      </div>
      <div className={styles.faq}>
        {children}
      </div>
    </div>
  </section>
)

export default Faq
