export function thousandSeparator(value: number) {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export function replaceDot(value: number) {
  return value.toString().replace(/\./g, ',')
}

export function round(value: number) {
  return Math.round(value)
}

export function reduceMillions(value: number) {
  const result = value / 1000000
  return result.toString()
}
