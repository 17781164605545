// extracted by mini-css-extract-plugin
export var wrapper = "f_b";
export var show = "f_J";
export var content = "f_v";
export var navigation = "f_K";
export var address = "f_L";
export var form = "f_M";
export var heading = "f_w";
export var box = "f_c";
export var link = "f_N";
export var external = "f_P";