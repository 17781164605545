import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import BackgroundLayer from './BackgroundLayer'
import * as styles from './index.module.css'

interface Props {
  children: React.ReactNode
}

interface LogoProps {
  alt: string
}

const Partners: React.FC<Props> = ({
  children,
}) => (
  <section className={styles.wrapper}>
    <BackgroundLayer />
    <div className={styles.partners}>
      {children}
    </div>
  </section>
)

export const Tatneft: React.FC<LogoProps> = ({
  alt,
}) => (
  <StaticImage
    className={styles.logo}
    alt={alt}
    src="../../assets/images/logo-tatneft.png"
    objectFit="cover"
    draggable={false}
    placeholder="blurred"
    quality={100}
  />
)
export const Sng: React.FC<LogoProps> = ({
  alt,
}) => (
  <StaticImage
    className={styles.logo}
    alt={alt}
    src="../../assets/images/logo-sng.png"
    objectFit="cover"
    draggable={false}
    placeholder="blurred"
    quality={100}
  />
)
export const Tattelecom: React.FC<LogoProps> = ({
  alt,
}) => (
  <StaticImage
    className={styles.logo}
    alt={alt}
    src="../../assets/images/logo-tattelecom.png"
    objectFit="cover"
    draggable={false}
    placeholder="blurred"
    quality={100}
  />
)
export const Sta: React.FC<LogoProps> = ({
  alt,
}) => (
  <StaticImage
    className={styles.logo}
    alt={alt}
    src="../../assets/images/logo-sta.png"
    objectFit="cover"
    draggable={false}
    placeholder="blurred"
    quality={100}
  />
)

export default Partners
