// extracted by mini-css-extract-plugin
export var wrapper = "w_b";
export var guides = "w_bX";
export var imageWrapper = "w_bh";
export var mobile = "w_bY";
export var overlay = "w_bZ";
export var fill = "w_b0";
export var tablet = "w_b1";
export var desktop = "w_b2";
export var spacer = "w_6";
export var liningWrapper = "w_b3";
export var lining = "w_b4";
export var dotsGroup = "w_b5";
export var transformTop = "w_b6";
export var gray = "w_b7";
export var transformBottom = "w_b8";