import React from 'react'
import * as styles from './index.module.css'

interface Wrapper {
  children: React.ReactNode
}

interface Props extends Wrapper {
  heading?: string | React.ReactNode
}

interface StepProps extends Wrapper {
  count: string | React.ReactNode
  heading: string | React.ReactNode
}

const Steps: React.FC<Props> = ({
  heading = 'Heading',
  children,
}) => (
  <section className={styles.wrapper}>
    <div className={styles.heading}>
      {heading}
    </div>
    <div className={styles.steps}>
      {children}
    </div>
  </section>
)

export const Step: React.FC<StepProps> = ({
  count,
  heading,
  children,
}) => (
  <div className={styles.step}>
    <div className={styles.stepCount}>{count}</div>
    <div className={styles.stepHeading}>{heading}</div>
    <div className={styles.stepDesc}>{children}</div>
  </div>
)

export default Steps
