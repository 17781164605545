import clsx from 'clsx'
import React, { useState } from 'react'
import * as styles from './index.module.css'

interface Props {
  autoFocus?: boolean
  value: string
  width?: string | number
  height?: string | number
  style?: React.CSSProperties
  type?: 'text' | 'tel' | 'email'
  placeholder?: string
  name?: string
  validated?: boolean
  onChange: (value: string) => void
}

const Input: React.FC<Props> = ({
  autoFocus = false,
  value,
  width = '11rem',
  height = '2.625rem',
  style,
  type = 'text',
  placeholder = '',
  name = '',
  validated = true,
  onChange,
}) => {
  const [touched, setTouched] = useState(false)
  const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.value)
  }
  return (
    <input
      onChange={changeHandler}
      onBlur={() => setTouched(true)}
      className={clsx(styles.input, !validated && touched && styles.error)}
      {...{
        autoFocus,
        value,
        type,
        placeholder,
        name,
      }}
      style={{
        width,
        height,
        borderRadius: height,
        ...style,
      }}
    />
  )
}

export default Input
